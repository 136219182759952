import React from 'react';
import Layout from '../components/Layout';
import { Link } from '@reach/router';
import ContactForm from '../components/ContactForm';

const ContactPage = () => {
  return (
    <Layout pageWrapper="page-contactus">
      <section className="breadcrumb">
        <div className="bg-image text-center">
          <h1>Quote</h1>
        </div>
        <div className>
          <ul className="pager middle">
            <li>Home</li>
            <li><Link to="/contact">Quote</Link></li>
          </ul>
        </div>
      </section>
      {/* CONTACT US */}
      <section className="main-padding">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="sectionTitle">
                <h2>How Can We Help?</h2>
                <p>Please fill out the information below with a brief description of the issue and we’ll contact you as soon as possible.</p>
              </div>
            </div>
            <div className="col-lg-8">
              <ContactForm />
            </div>
            <div className="col-lg-4">
              {/* Contact Sidebar */}
              <div className="contact_sidebar">
                <div className="media-box bg-sand">
                  <div className="media-icon">
                    <i className="fa fa-phone" />
                  </div>
                  <div className="media-content">
                    <h4>Phone</h4>
                    <p>
                      (262) 206-1655
                    </p>
                  </div>
                </div>
                <div className="media-box bg-sand">
                  <div className="media-icon">
                    <i className="fa fa-envelope" />
                  </div>
                  <div className="media-content">
                    <h4>Email</h4>
                    <p>
                      sales@onsitemachinecompany.com
                    </p>
                  </div>
                </div>
              </div>{/* Contact Sidebar Ends */}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ContactPage;
